import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { HyMaterialIconModule } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';
import { AddInternalUserDialogService } from '../../../../../components/add-internal-user-dialog/add-internal-user-dialog.service';

@Component({
  standalone: true,
  selector: 'app-admin-users-operations',
  templateUrl: './user-operations.component.html',
  styleUrls: ['./user-operations.component.scss'],
  imports: [CommonModule, MatButtonModule, HyMaterialIconModule, MatCardModule, MatDividerModule, RouterModule, TranslocoModule],
})
export class UserOperationsComponent {
  constructor(private readonly _addInternalUserDialogService: AddInternalUserDialogService) {}

  handleAddInternalUserWizard() {
    this._addInternalUserDialogService.handleAddInternalUserWizard();
  }
}
