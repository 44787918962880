import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ClientError, Maybe, UserCreateInput } from '@hxp/graphql';
import { KernelComponentsModule } from '@hxp/kernel';
import { DisplayUserGroup } from '@hxp/nucleus';
import { HxpToastService } from '@hxp/shared/common';
import { TranslatedToastService } from '@hxp/shared/i18n';
import { HyDialogModule, HyMaterialIconModule, HyTranslateModule, HyWizardModule } from '@hyland/ui';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { UserGroupsService } from '../../views/user-groups/user-groups.service';
import { sortByDisplayName } from '../../views/user-groups/utils/sort-by-display-name.utils';
import { defaultUserInformation } from '../../views/users/default-user';
import { UserEditInputGroups } from '../../views/users/views/user-information/components/add-user-to-groups/user-edit-input-groups';
import {
  UserFormComponent,
  UserInformationFormValues,
} from '../../views/users/views/user-information/components/user-form/user-form.component';
import { UserGroupsListComponent } from '../../views/users/views/user-information/components/user-groups-list/user-groups-list.component';
import { UserInformationService } from '../../views/users/views/user-information/services/user-information.service';
import { UserInformation } from '../../views/users/views/user-information/user-information';

export interface AddInternalUserDialogData {
  userInformation: UserInformationFormValues;
  userGroups: Maybe<string[]> | undefined;
}

enum WizardSteps {
  UserInformation = 0,
  UserGroups = 1,
}

@Component({
  selector: 'app-add-internal-user-dialog',
  templateUrl: './add-internal-user.dialog.html',
  styleUrls: ['./add-internal-user.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    HyWizardModule,
    MatStepperModule,
    MatIconModule,
    HyDialogModule,
    UserFormComponent,
    MatButtonModule,
    HyMaterialIconModule,
    UserGroupsListComponent,
    HyTranslateModule,
    KernelComponentsModule,
    MatProgressBarModule,
  ],
})
export class AddInternalUserDialog implements OnInit {
  readonly defaultUserInformation: UserInformation = defaultUserInformation;
  sortedUserGroupsForUser: DisplayUserGroup[] = [];
  submitting = false;
  allGroups$!: Observable<DisplayUserGroup[]>;
  wizardSteps = WizardSteps;

  @ViewChild(MatStepper, { static: false })
  wizard!: MatStepper;

  @ViewChild(UserFormComponent, { static: false })
  userForm!: UserFormComponent;

  private _userInformationFormValue!: UserInformationFormValues;
  private _userEditInputGroups: UserEditInputGroups = {
    addToGroups: [],
    removeFromGroups: [],
  };

  groupsLoading = false;

  constructor(
    private readonly _dialogRef: MatDialogRef<AddInternalUserDialog>,
    private readonly _userGroupsService: UserGroupsService,
    private readonly _userInformationService: UserInformationService,
    private readonly _translatedToastService: TranslatedToastService,
    private readonly _toastService: HxpToastService,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this._initializeAllGroups();
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  userInformationFormValueChanged(formValue: UserInformationFormValues): void {
    this._userInformationFormValue = formValue;
  }

  assignedUserGroupsChanged(userEditInputGroups: UserEditInputGroups): void {
    this._userEditInputGroups = userEditInputGroups;
  }

  moveBackToUserForm(): void {
    this.wizard.steps.first.select();
  }

  onConfirmed() {
    if (!this.userForm.form.valid) {
      this.userForm.form.markAllAsTouched();
      this.userForm.form.updateValueAndValidity();
      return;
    }

    return this.wizard._getFocusIndex() !== this.wizardSteps.UserInformation ? this.createUser() : this.wizard.next();
  }

  private createUser(): void {
    this.submitting = true;

    const userInput: UserCreateInput = {
      emails: [{ type: 'primary', value: this._userInformationFormValue.email }],
      userName: this._userInformationFormValue.username,
      familyName: this._userInformationFormValue.lastname,
      givenName: this._userInformationFormValue.firstname,
      addToGroups: this._userEditInputGroups.addToGroups,
    };

    this._userInformationService.createUserMutation(userInput).subscribe({
      next: (userPayload) => {
        if (userPayload?.value) {
          const error = userPayload.userErrors;
          if (error && error[0] && error[0].message) {
            this._toastService.warning(error[0].message);
          } else {
            this._translatedToastService.success('users.users-components.user-form.add-to-user-accounts-success-message', {
              displayName: userPayload.value.displayName,
            });
          }
          void this._router.navigate(['/identity', 'users'], {
            queryParams: {
              selectedUser: userPayload.value.id,
            },
          });
          this._dialogRef.close();
        } else {
          this._onCreateUserError(userPayload?.userErrors);
        }

        this.submitting = false;
      },
      error: () => {
        this._translatedToastService.error('users.users-components.user-form.save-user-error-message');
        this.moveBackToUserForm();
        this.submitting = false;
      },
    });
  }

  private _initializeAllGroups(): void {
    this.allGroups$ = this._userGroupsService.getUserGroups().pipe(
      filter((resp) => {
        this.groupsLoading = resp.loading;
        return !!resp.data;
      }),
      map((resp) => sortByDisplayName(resp.data.currentUser.homeAccount.account.groups)),
      catchError(() => {
        this.groupsLoading = false;
        return of([]);
      }),
    );
  }

  private _onCreateUserError(errors?: Array<Maybe<ClientError> | undefined> | null): void {
    this._displayToastErrorMessage(errors && errors[0]?.message);

    if (errors) {
      this.userForm.handleBackendPayloadError(errors);
    }

    this.moveBackToUserForm();
  }

  private _displayToastErrorMessage(message: Maybe<string> | undefined): void {
    if (message) {
      this._toastService.error(message);
    } else {
      this._translatedToastService.error('users.users-components.user-form.create-user-error-message');
    }
  }
}
