import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { HxpToastService } from '@hxp/shared/common';
import { HyTranslateService } from '@hyland/ui';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorHandlerService {
  constructor(
    private readonly _toastService: HxpToastService,
    private readonly _translate: HyTranslateService,
  ) {}

  handle(error: Error): void {
    if (error instanceof ApolloError) {
      this._handleApolloError(error);
      return;
    }
    this._handleGenericError();
  }

  private _handleApolloError(error: ApolloError) {
    if (error.networkError) {
      this._toastService.error(this.defineNetworkErrorMessage(error.networkError));
      return;
    }
    this._handleGenericError();
  }

  private _handleGenericError() {
    this._toastService.error(this.defineUnhandledErrorMessage());
  }

  private defineNetworkErrorMessage(error: ErrorWithStatusCode): string {
    if (error.status) {
      switch (error.status) {
        case 401: {
          return this._translate.get('server-errors.authentication-required-message');
        }
        case 403: {
          return this._translate.get('server-errors.not-authorized-message');
        }
        case 404: {
          return this._translate.get('server-errors.page-not-found-message');
        }
        case 500: {
          return this._translate.get('server-errors.system-failure-message');
        }
      }
    }
    return this.defineUnhandledErrorMessage();
  }

  private defineUnhandledErrorMessage() {
    return this._translate.get('server-errors.unhandled-error');
  }
}

export interface ErrorWithStatusCode extends Error {
  status?: number;
}
