import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DisplayUserGroup } from '@hxp/nucleus';
import { HySearchInputModule } from '@hyland/ui';
import { TranslocoModule } from '@jsverse/transloco';
import * as R from 'ramda';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AddUserToGroupsComponent } from '../add-user-to-groups/add-user-to-groups.component';
import { UserEditInputGroups } from '../add-user-to-groups/user-edit-input-groups';

@Component({
  selector: 'app-user-groups-list',
  templateUrl: './user-groups-list.component.html',
  styleUrls: ['./user-groups-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, NgIf, HySearchInputModule, AddUserToGroupsComponent, AsyncPipe],
})
export class UserGroupsListComponent implements OnInit, OnChanges {
  @Input()
  isLoading!: boolean;

  @Input()
  allUserGroups: DisplayUserGroup[] | null = [];

  @Input()
  set userGroups(value: DisplayUserGroup[] | null) {
    this.preselectedUsers = value ? R.clone(value) : [];
  }
  preselectedUsers: DisplayUserGroup[] = [];

  @Input()
  optionalLabel = false;

  @Output()
  assignedGroupsChanges = new EventEmitter<UserEditInputGroups>();

  assignedGroups: DisplayUserGroup[] = [];
  unassignedGroups$!: Observable<DisplayUserGroup[]>;

  readonly searchTerm$ = new Subject<string>();

  ngOnInit(): void {
    this._handleSearchTerm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allUserGroups && changes.allUserGroups.currentValue !== changes.allUserGroups.previousValue) {
      this._handleSearchTerm();
    }
  }

  onSelectedItemsChanged(userEditInputGroups: UserEditInputGroups) {
    this.assignedGroupsChanges.emit(userEditInputGroups);
  }

  private _handleSearchTerm(): void {
    this.unassignedGroups$ = this.searchTerm$.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map((searchTerm: string) => {
        const term = searchTerm.toLowerCase();
        return this.allUserGroups?.filter(({ displayName }) => displayName.toLowerCase().includes(term)) || [];
      }),
    );
  }
}
